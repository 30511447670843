<template>
  <transition name="fade">

    <div class="d-flex flex-column flex-xl-row" v-if="!state.loaderEnabled">
      <!--begin::Content-->
      <div class="flex-lg-row-fluid">
        <div style='line-height: 70px;  margin-top: -18px; background: #f4f4f500; margin-left: -30px; margin-right: -30px; padding-left: 30px; padding-right: 30px; margin-bottom: 8px;'>
          <ul class="nav nav-custom nav-pills border-0 fs-4 fw-bold mb-8" style="display: inline;">
            <li class="nav-item" style="float: left; background:none; color: #b96069;">
              <h1 class="text-gray-600 fw-bolder mt-2 my-1 fs-5 bg-title mb-5 card">
                <span v-html="pageTitle"></span><span v-if="state.bordereau.bor_n_readonly==1" class="text-danger fw-bolder">(LECTURE SEULE)</span>
              </h1>
            </li>
            <li class="nav-item " style="float: right; margin-top: 1px;" >
              <BordPDF title="" class="btn btn-secondary text-active-light me-6 ml-6 btn-custom-white h-45px" :bor_n_seq="state.bor_n_seq" />
            </li>
            <li class="nav-item me-3" style="float: right;">
              <el-dropdown>
                <el-tooltip content="Exporter le détail du bordereau" placement="top-end">
                  <a class="btn btn-secondary text-active-light me-6 ml-6 btn-custom-white h-45px">
                    <span class="svg-icon svg-icon-1 svg-icon-white" style=" margin-right: 0rem;"><inline-svg src="media/icons/duotune/arrows/arr044.svg"/> </span>
                  </a>
                </el-tooltip>
                <template #dropdown>
                  <el-dropdown-menu>
                    <el-dropdown-item @click="csvExport()">Export CSV</el-dropdown-item>
                    <el-dropdown-item @click="xmlExport('ead')">Export BACH</el-dropdown-item>
                    <el-dropdown-item @click="xmlExport('xml')">Export AAC</el-dropdown-item>
                  </el-dropdown-menu>
                </template>
              </el-dropdown>
            </li>
          </ul>
        </div>


      <div class="card" style="width: 100%;">
        <div class="card-header border-0 pt-6">
          <div class="card-title" style="width:100%;">
            <h2>Description du bordereau</h2>
          </div>
        </div>
        <div class="card-body pt-0">
          Veuillez sélectionner un type de versement ci-dessous.
        </div>
      </div>
      <div class="row g-5 g-xl-8 mt-0" v-if="!(state.bordereau.bor_n_readonly==1)">
        <div class="col-xl-4">
          <div class="card-xl-stretch mb-xl-8 card bgi-no-repeat bloc-type-versement" 
               :class="{active: form.bor_n_type_versement==1 }"
               @click="form.bor_n_type_versement = 1"
               style="background-image: url('media/svg/shapes/abstract-4.svg'); background-position: right top; background-size: 30% auto; padding: 1rem 1.25rem;" >
            <div class="card-body p-0">
              <a class="card-title fw-bolder text-muted text-hover-primary fs-4">PAPIER</a>
              <div class="fw-bolder text-primary my-6">DOSSIERS PHYSIQUES</div>
            </div>
          </div>
        </div>
        <div class="col-xl-4">
          <div class="card-xl-stretch mb-xl-8 card bgi-no-repeat bloc-type-versement"
               :class="{active: form.bor_n_type_versement==2 }"
               @click="form.bor_n_type_versement = 2"
               style="background-image: url('media/svg/shapes/abstract-2.svg'); background-position: right top; background-size: 30% auto; padding: 1rem 1.25rem;">
            <div class="card-body p-0">
              <a class="card-title fw-bolder text-muted text-hover-primary fs-4">NUMERIQUES</a>
              <div class="fw-bolder text-primary my-6">FICHIER NUMÉRIQUES</div>
            </div>
          </div>
        </div>
        <div class="col-xl-4">
          <div class="card-xl-stretch mb-xl-8 card bgi-no-repeat bloc-type-versement"
              :class="{active: form.bor_n_type_versement==3 }"      
              @click="form.bor_n_type_versement = 3"    
               style="background-image: url('media/svg/shapes/abstract-1.svg'); background-position: right top; background-size: 30% auto; padding: 1rem 1.25rem;">
            <div class="card-body p-0">
              <a class="card-title fw-bolder text-muted text-hover-primary fs-4">MIXTE</a>
              <div class="fw-bolder text-primary my-6">PAPIER ET NUMÉRIQUES</div>
            </div>
          </div>
        </div>
      </div>

      <div class="row g-5 g-xl-8 mt-0" v-if="state.bordereau.bor_n_readonly==1">
        <div class="col-xl-12">
          <div class="card-xl-stretch mb-xl-8 card bgi-no-repeat "
                style="background-image: url('media/svg/shapes/abstract-2.svg'); background-position: right top; background-size: 30% auto; padding: 1rem 1.25rem;">
            <div class="card-body p-0">
              <a class="card-title fw-bolder text-muted text-hover-primary fs-4" v-if="form.bor_n_type_versement == 1">PAPIER</a>
              <a class="card-title fw-bolder text-muted text-hover-primary fs-4" v-if="form.bor_n_type_versement == 2">NUMERIQUES</a>
              <a class="card-title fw-bolder text-muted text-hover-primary fs-4" v-if="form.bor_n_type_versement == 3">MIXTE</a>
              <div class="fw-bolder text-primary my-6" v-if="form.bor_n_type_versement == 1">DOSSIERS PHYSIQUES</div>
              <div class="fw-bolder text-primary my-6" v-if="form.bor_n_type_versement == 2">FICHIER NUMÉRIQUES</div>
              <div class="fw-bolder text-primary my-6" v-if="form.bor_n_type_versement == 3" >PAPIER ET NUMÉRIQUES</div>
            </div>
          </div>
        </div>

      </div>

      <div class="card mb-7" style="width: 100%;" v-if="form.bor_n_type_versement == 1 || form.bor_n_type_versement == 3">
        <div class="card-header border-0 pt-6">
          <div class="card-title" style="width:100%;">
            <h4>Versement de type : Papier</h4>
          </div>
        </div>
        <div class="card-body pt-0">
          
          <el-row :gutter="10" style="width: 100%">
            <el-col :span="8">
              <div class="row row-user">
                <el-input v-model="form.bor_n_papier_total_articles" placeholder="" class="m-2" style="max-width: 420px;" :disabled="state.bordereau.bor_n_readonly==1">
                  <template #prepend>Nombre total d'articles</template>
                  <template #append> 
                    <el-tooltip content="Calculer le nombre total d'article du bordereau" placement="right" v-if="!(state.bordereau.bor_n_readonly==1)">
                      <a class="btn btn-sm btn-light-primary btn-custom-white btn-mid-width" @click='totArticle()'>
                        <span class="svg-icon svg-icon-1 svg-icon-primary" style="margin-right: 0rem;">
                        <inline-svg src="media/icons/duotune/arrows/arr059.svg"/> </span>
                      </a>
                    </el-tooltip>
                  </template>
                </el-input>
              </div>
            </el-col>
            <el-col :span="8">
              <div class="row row-user">
                <el-input v-model="form.bor_n_papier_nb_boite" placeholder="" class="m-2" style="max-width: 400px;" :disabled="state.bordereau.bor_n_readonly==1">
                  <template #prepend>Nombre de boîtes</template>
                  <template #append>boites</template>
                </el-input>
              </div>
            </el-col>
            <el-col :span="8">
              <div class="row row-user">
                <el-input v-model="form.bor_n_papier_metrage" type="number" placeholder="" class="m-2 removeInputRows" style="max-width: 300px;" :disabled="state.bordereau.bor_n_readonly==1">
                  <template #prepend>Métrage </template>
                  <template #append> 
                    ml&nbsp;
                    <el-tooltip content="Calculer le métrage linaire des articles du bordereau" placement="right" v-if="!(state.bordereau.bor_n_readonly==1)">
                      <a class="btn btn-sm btn-light-primary btn-custom-white btn-mid-width" @click='totMetrage()'>
                        <span class="svg-icon svg-icon-1 svg-icon-primary" style="margin-right: 0rem;">
                        <inline-svg src="media/icons/duotune/arrows/arr059.svg"/> </span>
                      </a>
                    </el-tooltip>
                  </template>
                </el-input>
              </div>
            </el-col>        
          </el-row>
          <el-row :gutter="10" class="mt-5">
            <el-col :span="24">
              <div class="row row-user">
                <div class="el-input el-input-group el-input-group--append el-input-group--prepend m-2">
                  <div class="el-input-group__prepend" style='width: 200px'>Type de support</div>
                  <div v-if="form">
                    <el-checkbox v-model="form.bor_c_papier_type_support.papier" label="Papier" size="large" border :disabled="state.bordereau.bor_n_readonly==1" />
                    <el-checkbox v-model="form.bor_c_papier_type_support.photo" label="Photo" size="large" border :disabled="state.bordereau.bor_n_readonly==1" />
                    <el-checkbox v-model="form.bor_c_papier_type_support.plan" label="Plan" size="large" border  :disabled="state.bordereau.bor_n_readonly==1" />
                    <el-checkbox v-model="form.bor_c_papier_type_support.autre" label="Autre" size="large" border :disabled="state.bordereau.bor_n_readonly==1" />
                  </div>
                </div>
              </div>      
            </el-col>   
          </el-row>


          <el-row :gutter="10" class="mt-5">
            <el-col :span="24">
              <div class="row row-user">
                <div class="el-input el-input-group el-input-group--append el-input-group--prepend m-2">
                  <div class="el-input-group__prepend" style='width: 200px'>Format</div>
                  <div v-if="form">
                    <el-checkbox v-model="form.bor_c_papier_format.a4" label="A4" size="large" border :disabled="state.bordereau.bor_n_readonly==1" />
                    <el-checkbox v-model="form.bor_c_papier_format.a3" label="A3" size="large" border :disabled="state.bordereau.bor_n_readonly==1" />
                    <el-checkbox v-model="form.bor_c_papier_format.autre" label="Autre" size="large" border :disabled="state.bordereau.bor_n_readonly==1" />
                  </div>
                </div>
              </div>      
            </el-col>   
          </el-row>


        </div>
      </div>




      <div class="card mb-7" style="width: 100%;" v-if="form.bor_n_type_versement == 2 || form.bor_n_type_versement == 3">
        <div class="card-header border-0 pt-6">
          <div class="card-title" style="width:100%;">
            <h4>Versement de type : Fichier numériques</h4>
          </div>
        </div>
        <div class="card-body pt-0">
          
          <el-row :gutter="10" style="width: 100%">
            <el-col :span="8">
              <div class="row row-user">
                <el-input v-model="form.bor_n_numerique_total_fichier" placeholder="" class="m-2" style="max-width: 420px;" :disabled="state.bordereau.bor_n_readonly==1">
                  <template #prepend><div style='width: 159px'>Nombre de fichier</div></template>
                </el-input>
              </div>
            </el-col>
            <el-col :span="8">
              <div class="row row-user">
                <el-input v-model="form.bor_n_numerique_volumetrie_taille" placeholder="" class="m-2" style="max-width: 400px;" :disabled="state.bordereau.bor_n_readonly==1">
                  <template #prepend>Volumétrie</template>
                  <template #append>
                    <select class="form-select m-2 mt-0" style="width: 100px;" aria-label="Selection" v-model="form.bor_c_numerique_volumetrie_type" :disabled="state.bordereau.bor_n_readonly==1">
                      <option value="Ko">Ko</option>
                      <option value="Mo">Mo</option>
                      <option value="Go">Go</option>
                      <option value="To">To</option>
                      <option value="Po">Po</option>
                    </select>
                  </template>
                </el-input>
              </div>
            </el-col>   
          </el-row>

          <el-row :gutter="10" class="mt-5">
            <el-col :span="24">
              <div class="row row-user">
                <div class="el-input el-input-group el-input-group--append el-input-group--prepend m-2">
                  <div class="el-input-group__prepend" style='width: 200px'>Support de stockage</div>
                  <div>
                    <el-checkbox v-model="form.bor_c_numerique_type_support.cd" label="CD / DVD" size="large" border :disabled="state.bordereau.bor_n_readonly==1" />
                    <el-checkbox v-model="form.bor_c_numerique_type_support.usb" label="Clef USB" size="large" border :disabled="state.bordereau.bor_n_readonly==1" />
                    <el-checkbox v-model="form.bor_c_numerique_type_support.disque" label="Disque dur externe" size="large" border :disabled="state.bordereau.bor_n_readonly==1" />
                    <el-checkbox v-model="form.bor_c_numerique_type_support.autre" label="Autre" size="large" border :disabled="state.bordereau.bor_n_readonly==1" />
                  </div>
                </div>
              </div>      
            </el-col>   
          </el-row>

          <el-row :gutter="10" class="mt-5">
            <el-col :span="24">
              <div class="row row-user">
                <div class="el-input el-input-group el-input-group--append el-input-group--prepend m-2">
                  <div class="el-input-group__prepend" style='width: 200px'>Format</div>
                  <div>
                    <el-checkbox v-model="form.bor_c_numerique_format.bureautique" label="Bureautique" size="large" border :disabled="state.bordereau.bor_n_readonly==1" />
                    <el-checkbox v-model="form.bor_c_numerique_format.pdf" label="PDF" size="large" border :disabled="state.bordereau.bor_n_readonly==1" />
                    <el-checkbox v-model="form.bor_c_numerique_format.image" label="Image" size="large" border  :disabled="state.bordereau.bor_n_readonly==1" />
                    <el-checkbox v-model="form.bor_c_numerique_format.video" label="Vidéo" size="large" border :disabled="state.bordereau.bor_n_readonly==1" />
                    <el-checkbox v-model="form.bor_c_numerique_format.son" label="Son" size="large" border :disabled="state.bordereau.bor_n_readonly==1" />
                    <el-checkbox v-model="form.bor_c_numerique_format.autre" label="Autre" size="large" border :disabled="state.bordereau.bor_n_readonly==1" />
                  </div>
                </div>
              </div>      
            </el-col>   
          </el-row>


        </div>
      </div>



      <div class="card mt-1" style="width: 100%;">
        <div class="card-header border-0 pt-6">
          <div class="card-title" style="width:100%;">
            <h4>Description sommaire</h4>
          </div>
        </div>
        <div class="card-body pt-0">
          
          <el-row :gutter="10" style="width: 100%">
            <el-col :span="8">
              <div class="row row-user">
                <el-input v-model="form.bor_c_article_extreme_mini" placeholder="" class="m-2" style="max-width: 347px;" :disabled="state.bordereau.bor_n_readonly==1">
                  <template #prepend><div style='width: 159px'>Articles extrêmes</div></template>
                </el-input>
                <el-input v-model="form.bor_c_article_extreme_maxi" placeholder="" class="m-2" style="max-width: 420px;" :disabled="state.bordereau.bor_n_readonly==1">
                  <template #prepend><div style='width: 159px'></div></template>
                  <template #append> 
                    <el-tooltip content="Calculer les articles extrêmes" placement="right" v-if="!(state.bordereau.bor_n_readonly==1)">
                      <a class="btn btn-sm btn-light-primary btn-custom-white btn-mid-width" @click='articlesExtremes()'>
                        <span class="svg-icon svg-icon-1 svg-icon-primary" style="margin-right: 0rem;">
                        <inline-svg src="media/icons/duotune/arrows/arr059.svg"/> </span>
                      </a>
                    </el-tooltip>
                  </template>
                </el-input>
              </div>
            </el-col>
            <el-col :span="8">
              <div class="row row-user">
                <el-input v-model="form.bor_c_annee_mini" placeholder="" class="m-2" style="max-width: 347px;" :disabled="state.bordereau.bor_n_readonly==1">
                  <template #prepend><div style='width: 159px'>Années extrêmes</div></template>
                </el-input>
                <el-input v-model="form.bor_c_annee_maxi" placeholder="" class="m-2" style="max-width: 420px;" :disabled="state.bordereau.bor_n_readonly==1">
                  <template #prepend><div style='width: 159px'></div></template>
                  <template #append> 
                    <el-tooltip content="Calculer les années extrêmes" placement="right" v-if="!(state.bordereau.bor_n_readonly==1)">
                      <a class="btn btn-sm btn-light-primary btn-custom-white btn-mid-width" @click='anneesExtremes()'>
                        <span class="svg-icon svg-icon-1 svg-icon-primary" style="margin-right: 0rem;">
                        <inline-svg src="media/icons/duotune/arrows/arr059.svg"/> </span>
                      </a>
                    </el-tooltip>
                  </template>
                </el-input>
              </div>
            </el-col> 
          </el-row>
          <el-row :gutter="10" class="mt-5">
            <el-col :span="24">
              <div class="row row-user">
                <el-input v-model="form.bor_c_description_sommaire" placeholder="" class="m-2" style="width: 100%;" :disabled="state.bordereau.bor_n_readonly==1">
                  <template #prepend><div style='width: 159px'>Description</div></template>
                </el-input>
              </div>
            </el-col>   
          </el-row>

        </div>
      </div>

    </div>
  </div>

  </transition>
</template>

<script lang="ts">
import { defineComponent, ref, onMounted, reactive, watch, computed } from "vue";
import * as moment from "moment";
import BordPDF from "@/views/still/bord/pdf.vue"; 
import { useStore } from "vuex";
import mAxiosApi from "@/api";

export default defineComponent({
  name: "Overview",
  components: {
    BordPDF
  },
  setup() {

    const store = useStore();
    const pageTitle = computed(() => {
      return store.getters.pageTitle;
    });

    moment.default.locale("fr");
    const state = reactive({
      bor_n_seq : parseInt(localStorage.getItem("currentBord") || "0"),
      bordereau: {} as any,
      loaderEnabled: true,
      myList: [] as any,
      drawer: ref(false),
      sourcePDF: "",
      mOffre: "",
      initialMyList: [],
      loadingDatatable: 0,
      myListLiv: [],
      lSN: [],
      mObj: {},
      zUser: {},
      lProducteurs: [] as any,
      lUsers: [] as any,
    });


    const form = reactive({
        bor_n_seq:0,
        bor_n_type_versement: 0,
        bor_c_papier_type_support: {
          papier: false, photo: false, plan: false, autre: false,
        } as any,
        bor_c_papier_format: {
          a4: false, a3: false, autre: false,
        } as any,
        bor_c_numerique_type_support: {
          cd: false, usb: false, disque: false, autre: false,
        } as any,
        bor_c_numerique_format: {
          bureautique: false, pdf: false, image: false, video: false, son: false, autre: false,
        } as any,

        bor_n_papier_total_articles: '',
        bor_n_papier_nb_boite:'',
        bor_n_papier_metrage: '',
        bor_n_numerique_total_fichier: '',
        bor_n_numerique_volumetrie_taille: '',
        bor_c_numerique_volumetrie_type : '',
        bor_c_article_extreme_mini: '',
        bor_c_article_extreme_maxi: '',
        bor_c_annee_mini: '',
        bor_c_annee_maxi:'',
        bor_c_description_sommaire:'',

    })

    const tableHeader = ref([
      { name: "N° d'ordre", key: "art_n_num_ordre", sortable: false,},
      { name: "Titre", key: "art_c_categorie", sortable: false,},
      { name: "Libellé", key: "art_c_libelle", sortable: false,},
      { name: "Dates", key: "art_n_annee_mini", sortable: false,},
      { name: "DUA", key: "art_n_dua", sortable: false,},
      { name: "Observations", key: "art_c_observations", sortable: false,},
      { name: "", key: "art_n_seq", sortable: false,},

    ]);

    watch(form, () => {
      editBordereau(form);
    });

    const editBordereau = async (data) => {
      let mData: any = JSON.stringify(data);
      mData = JSON.parse(mData);

      mData.bor_c_papier_type_support = JSON.stringify(data.bor_c_papier_type_support);
      mData.bor_c_papier_format = JSON.stringify(data.bor_c_papier_format);
      mData.bor_c_numerique_type_support = JSON.stringify(data.bor_c_numerique_type_support);
      mData.bor_c_numerique_format = JSON.stringify(data.bor_c_numerique_format);

      // console.log(mData.bor_c_papier_type_support);
      if (mData.bor_n_seq> 0 ) mAxiosApi.post("/editBord", mData)
    };


    const tableFilters = ref([
      { name: "titre", key: "art_c_categorie", label: "art_c_categorie", },
    ]);

    const formatDate = (d) => {
      return moment.default(d).format("DD.MM.YYYY");
    };
    const formatDateAgo = (d) => {
      return moment.default(d, "YYYY-MM-DD").fromNow();
    };

    const affDetail = (d) => {
      state.zUser = d;
    };

    onMounted(async () => {

      const myListLiv = await getAxios("/getSVoneBord/"+ parseInt(localStorage.getItem("currentBord") || "0"));
      const res = myListLiv.results;
      state.bordereau = myListLiv.results

      form.bor_n_type_versement = res.bor_n_type_versement >=1 ? res.bor_n_type_versement : 1;
      form.bor_c_papier_type_support = res.bor_c_papier_type_support ? JSON.parse(res.bor_c_papier_type_support) : {papier: false, photo: false, plan: false, autre: false};
      form.bor_c_papier_format = res.bor_c_papier_format ? JSON.parse(res.bor_c_papier_format) : {a4: false, a3: false, autre: false};
      form.bor_c_numerique_type_support = res.bor_c_numerique_type_support ? JSON.parse(res.bor_c_numerique_type_support) : {cd: false, usb: false, disque: false, autre: false};
      form.bor_c_numerique_format = res.bor_c_numerique_format ? JSON.parse(res.bor_c_numerique_format) : {bureautique: false, pdf: false, image: false, video: false, son: false, autre: false};

      form.bor_n_papier_total_articles = res.bor_n_papier_total_articles;
      form.bor_n_papier_nb_boite = res.bor_n_papier_nb_boite;
      form.bor_n_papier_metrage = res.bor_n_papier_metrage;
      form.bor_n_numerique_total_fichier =res.bor_n_numerique_total_fichier;
      form.bor_n_numerique_volumetrie_taille = res.bor_n_numerique_volumetrie_taille;
      form.bor_c_numerique_volumetrie_type = res.bor_c_numerique_volumetrie_type;
      form.bor_c_article_extreme_mini = res.bor_c_article_extreme_mini;
      form.bor_c_article_extreme_maxi = res.bor_c_article_extreme_maxi;
      form.bor_c_annee_mini = res.bor_c_annee_mini;
      form.bor_c_annee_maxi = res.bor_c_annee_maxi;
      form.bor_c_description_sommaire = res.bor_c_description_sommaire;
      form.bor_n_seq = res.bor_n_seq;

      state.loaderEnabled = false;
    });


    const totArticle = async () => {
      let myListLiv = {} as any;
      myListLiv = await getAxios("/getSVArticles/" + parseInt(localStorage.getItem("currentBord") || "0"));
      form.bor_n_papier_total_articles = myListLiv.results.length;
    };

    const totMetrage = async () => {
      let myListLiv = {} as any;
      myListLiv = await getAxios("/getSVArticlesML/" + parseInt(localStorage.getItem("currentBord") || "0"));
      form.bor_n_papier_metrage = Math.round(myListLiv.results.art_metrage_lineaire * 100) / 100 as any;
    };
    

    const articlesExtremes = async () => {
      let myListLiv = {} as any;
      myListLiv = await getAxios("/getSVArticles/" + parseInt(localStorage.getItem("currentBord") || "0"));
      form.bor_c_article_extreme_mini = "1";
      form.bor_c_article_extreme_maxi = myListLiv.results.length;
    };

    const anneesExtremes = async () => {
      let myListLiv = {} as any;
      myListLiv = await getAxios("/getSVArticlesMaxAnnees/" + parseInt(localStorage.getItem("currentBord") || "0"));
      //console.log(myListLiv);
      form.bor_c_annee_mini = myListLiv.results.art_n_annee_mini;
      form.bor_c_annee_maxi = myListLiv.results.art_n_annee_maxi;
    };

    const xmlExport = async(type) => {
      const xmlDoc = await getAxios("/xmlBordereau/"+ type +"/" + state.bordereau.bor_n_seq)
      let xmlContent = "data:application/xml;base64," + xmlDoc.xml;
      const link = document.createElement("a");
      link.setAttribute("href", xmlContent);
      
      const newName = state.bordereau.bor_c_identifiant.replace("/", "_");
      const filename = newName + ".xml";

      link.setAttribute("download", filename);
      link.click();
    }

    const csvExport = async () => {
      
      const mArray = [] as any;     
      state.initialMyList.forEach(element => {
        let myElem = JSON.stringify(element);
        
        myElem = myElem.replaceAll('\\n', " ");
        myElem = myElem.replaceAll('\\r', " ");
        myElem = myElem.replaceAll(";", '.');        
        mArray.push(JSON.parse(myElem));
      });

      let csvContent = "data:text/csv;charset=utf-8,";
      csvContent += [
        Object.keys(mArray[0]).join(";"),
        ...mArray.map(item => Object.values(item).join(";"))
      ]
        .join("\n")
        .replace(/(^\[)|(\]$)/gm, "");

      const data = encodeURI(csvContent);
      const link = document.createElement("a");
      link.setAttribute("href", data);
      
      const filename = "SV_Export_Bordereau_1324_" + moment.default().format("DD.MM.YYYY_H:mm:ss") + ".csv";

      link.setAttribute("download", filename);
      link.click();
    };


    return {
      tableHeader,
      state,
      formatDate,
      formatDateAgo,
      tableFilters,
      affDetail,
      form,
      totArticle,
      totMetrage,
      articlesExtremes,
      anneesExtremes,
      pageTitle,
      xmlExport,
      csvExport
    };
  },
});

export async function getAxios(request: string): Promise<any> {
  const response = await mAxiosApi.get(request);
  return response.data;
}
</script>


import { defineComponent, ref, onMounted, reactive, watch, computed } from "vue";
import * as moment from "moment";
import BordPDF from "@/views/still/bord/pdf.vue"; 
import { useStore } from "vuex";
import mAxiosApi from "@/api";

export default defineComponent({
  name: "Overview",
  components: {
    BordPDF
  },
  setup() {

    const store = useStore();
    const pageTitle = computed(() => {
      return store.getters.pageTitle;
    });

    moment.default.locale("fr");
    const state = reactive({
      bor_n_seq : parseInt(localStorage.getItem("currentBord") || "0"),
      bordereau: {} as any,
      loaderEnabled: true,
      myList: [] as any,
      drawer: ref(false),
      sourcePDF: "",
      mOffre: "",
      initialMyList: [],
      loadingDatatable: 0,
      myListLiv: [],
      lSN: [],
      mObj: {},
      zUser: {},
      lProducteurs: [] as any,
      lUsers: [] as any,
    });


    const form = reactive({
        bor_n_seq:0,
        bor_n_type_versement: 0,
        bor_c_papier_type_support: {
          papier: false, photo: false, plan: false, autre: false,
        } as any,
        bor_c_papier_format: {
          a4: false, a3: false, autre: false,
        } as any,
        bor_c_numerique_type_support: {
          cd: false, usb: false, disque: false, autre: false,
        } as any,
        bor_c_numerique_format: {
          bureautique: false, pdf: false, image: false, video: false, son: false, autre: false,
        } as any,

        bor_n_papier_total_articles: '',
        bor_n_papier_nb_boite:'',
        bor_n_papier_metrage: '',
        bor_n_numerique_total_fichier: '',
        bor_n_numerique_volumetrie_taille: '',
        bor_c_numerique_volumetrie_type : '',
        bor_c_article_extreme_mini: '',
        bor_c_article_extreme_maxi: '',
        bor_c_annee_mini: '',
        bor_c_annee_maxi:'',
        bor_c_description_sommaire:'',

    })

    const tableHeader = ref([
      { name: "N° d'ordre", key: "art_n_num_ordre", sortable: false,},
      { name: "Titre", key: "art_c_categorie", sortable: false,},
      { name: "Libellé", key: "art_c_libelle", sortable: false,},
      { name: "Dates", key: "art_n_annee_mini", sortable: false,},
      { name: "DUA", key: "art_n_dua", sortable: false,},
      { name: "Observations", key: "art_c_observations", sortable: false,},
      { name: "", key: "art_n_seq", sortable: false,},

    ]);

    watch(form, () => {
      editBordereau(form);
    });

    const editBordereau = async (data) => {
      let mData: any = JSON.stringify(data);
      mData = JSON.parse(mData);

      mData.bor_c_papier_type_support = JSON.stringify(data.bor_c_papier_type_support);
      mData.bor_c_papier_format = JSON.stringify(data.bor_c_papier_format);
      mData.bor_c_numerique_type_support = JSON.stringify(data.bor_c_numerique_type_support);
      mData.bor_c_numerique_format = JSON.stringify(data.bor_c_numerique_format);

      // console.log(mData.bor_c_papier_type_support);
      if (mData.bor_n_seq> 0 ) mAxiosApi.post("/editBord", mData)
    };


    const tableFilters = ref([
      { name: "titre", key: "art_c_categorie", label: "art_c_categorie", },
    ]);

    const formatDate = (d) => {
      return moment.default(d).format("DD.MM.YYYY");
    };
    const formatDateAgo = (d) => {
      return moment.default(d, "YYYY-MM-DD").fromNow();
    };

    const affDetail = (d) => {
      state.zUser = d;
    };

    onMounted(async () => {

      const myListLiv = await getAxios("/getSVoneBord/"+ parseInt(localStorage.getItem("currentBord") || "0"));
      const res = myListLiv.results;
      state.bordereau = myListLiv.results

      form.bor_n_type_versement = res.bor_n_type_versement >=1 ? res.bor_n_type_versement : 1;
      form.bor_c_papier_type_support = res.bor_c_papier_type_support ? JSON.parse(res.bor_c_papier_type_support) : {papier: false, photo: false, plan: false, autre: false};
      form.bor_c_papier_format = res.bor_c_papier_format ? JSON.parse(res.bor_c_papier_format) : {a4: false, a3: false, autre: false};
      form.bor_c_numerique_type_support = res.bor_c_numerique_type_support ? JSON.parse(res.bor_c_numerique_type_support) : {cd: false, usb: false, disque: false, autre: false};
      form.bor_c_numerique_format = res.bor_c_numerique_format ? JSON.parse(res.bor_c_numerique_format) : {bureautique: false, pdf: false, image: false, video: false, son: false, autre: false};

      form.bor_n_papier_total_articles = res.bor_n_papier_total_articles;
      form.bor_n_papier_nb_boite = res.bor_n_papier_nb_boite;
      form.bor_n_papier_metrage = res.bor_n_papier_metrage;
      form.bor_n_numerique_total_fichier =res.bor_n_numerique_total_fichier;
      form.bor_n_numerique_volumetrie_taille = res.bor_n_numerique_volumetrie_taille;
      form.bor_c_numerique_volumetrie_type = res.bor_c_numerique_volumetrie_type;
      form.bor_c_article_extreme_mini = res.bor_c_article_extreme_mini;
      form.bor_c_article_extreme_maxi = res.bor_c_article_extreme_maxi;
      form.bor_c_annee_mini = res.bor_c_annee_mini;
      form.bor_c_annee_maxi = res.bor_c_annee_maxi;
      form.bor_c_description_sommaire = res.bor_c_description_sommaire;
      form.bor_n_seq = res.bor_n_seq;

      state.loaderEnabled = false;
    });


    const totArticle = async () => {
      let myListLiv = {} as any;
      myListLiv = await getAxios("/getSVArticles/" + parseInt(localStorage.getItem("currentBord") || "0"));
      form.bor_n_papier_total_articles = myListLiv.results.length;
    };

    const totMetrage = async () => {
      let myListLiv = {} as any;
      myListLiv = await getAxios("/getSVArticlesML/" + parseInt(localStorage.getItem("currentBord") || "0"));
      form.bor_n_papier_metrage = Math.round(myListLiv.results.art_metrage_lineaire * 100) / 100 as any;
    };
    

    const articlesExtremes = async () => {
      let myListLiv = {} as any;
      myListLiv = await getAxios("/getSVArticles/" + parseInt(localStorage.getItem("currentBord") || "0"));
      form.bor_c_article_extreme_mini = "1";
      form.bor_c_article_extreme_maxi = myListLiv.results.length;
    };

    const anneesExtremes = async () => {
      let myListLiv = {} as any;
      myListLiv = await getAxios("/getSVArticlesMaxAnnees/" + parseInt(localStorage.getItem("currentBord") || "0"));
      //console.log(myListLiv);
      form.bor_c_annee_mini = myListLiv.results.art_n_annee_mini;
      form.bor_c_annee_maxi = myListLiv.results.art_n_annee_maxi;
    };

    const xmlExport = async(type) => {
      const xmlDoc = await getAxios("/xmlBordereau/"+ type +"/" + state.bordereau.bor_n_seq)
      let xmlContent = "data:application/xml;base64," + xmlDoc.xml;
      const link = document.createElement("a");
      link.setAttribute("href", xmlContent);
      
      const newName = state.bordereau.bor_c_identifiant.replace("/", "_");
      const filename = newName + ".xml";

      link.setAttribute("download", filename);
      link.click();
    }

    const csvExport = async () => {
      
      const mArray = [] as any;     
      state.initialMyList.forEach(element => {
        let myElem = JSON.stringify(element);
        
        myElem = myElem.replaceAll('\\n', " ");
        myElem = myElem.replaceAll('\\r', " ");
        myElem = myElem.replaceAll(";", '.');        
        mArray.push(JSON.parse(myElem));
      });

      let csvContent = "data:text/csv;charset=utf-8,";
      csvContent += [
        Object.keys(mArray[0]).join(";"),
        ...mArray.map(item => Object.values(item).join(";"))
      ]
        .join("\n")
        .replace(/(^\[)|(\]$)/gm, "");

      const data = encodeURI(csvContent);
      const link = document.createElement("a");
      link.setAttribute("href", data);
      
      const filename = "SV_Export_Bordereau_1324_" + moment.default().format("DD.MM.YYYY_H:mm:ss") + ".csv";

      link.setAttribute("download", filename);
      link.click();
    };


    return {
      tableHeader,
      state,
      formatDate,
      formatDateAgo,
      tableFilters,
      affDetail,
      form,
      totArticle,
      totMetrage,
      articlesExtremes,
      anneesExtremes,
      pageTitle,
      xmlExport,
      csvExport
    };
  },
});

export async function getAxios(request: string): Promise<any> {
  const response = await mAxiosApi.get(request);
  return response.data;
}
